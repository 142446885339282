<template>
  <div>Template Search Select</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Template Search Select',
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    category: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hotDestinations: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      device: 'GET_DEVICE',
      enableSearchAgentState: 'GET_ENABLE_SEARCH_AGENT_STATE',
    }),
    stateSelected: {
      get() {
        const ts = this.$store.getters.GET_SEARCH_CONTENT.dest;
        return ts || '';
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['dest', value]);
      },
    },
    stateSearchable() {
      return this.countSelect > 0;
    },
  },
  watch: {
    lang() {
      if (this.category && this.category.packageType !== 'HO') this.makeSelectOption();
    },
    selected() {
      this.$emit('changeDestination', this.selected);
      this.stateSelected = this.selected;
    },
    stateSelected() {
      this.selected = this.stateSelected;
    },
    category() {
      if (this.category && this.category.packageType !== 'HO') this.makeSelectOption();
    },
  },
  data() {
    return {
      selected: 'null',
      countSelect: 0,
      destinationList: [],
      destinationOptions: [],
      query: this.$route.query,
    };
  },
  mounted() {
    if (this.category && this.category.packageType !== 'HO') this.makeSelectOption();
  },
  methods: {
    makeSelectOption() {
      this.destinationList = this.category.destinations;
      this.destinationOptions = [];
      this.destinationList.forEach((item, inx) => {
        this.destinationOptions.push({
          id: inx,
          value: item.code,
          label: item.countryName[this.lang] ? `${item.name[this.lang]}, ${item.countryName[this.lang]}` : item.name[this.lang],
        });
      });
      this.destinationOptions.sort((a, b) => (a.label < b.label ? -1 : 1));
      this.getDestList();
    },
    getDestList() {
      const { categoryId } = this.$route.query;
      if (this.isLanding && (this.query.dealType === 'vacation_pack' || this.query.dealType === 'Flight_Only' || !this.query.dealType)) {
        const dest = this.query.destination;
        this.selected = this.destinationOptions.find((item) => (item.value === dest));
      } else if (categoryId !== 'Organize_tour_packages') {
        const strDestination = this.query.dest || this.query.destination || '',
          destIdList = strDestination !== '' ? decodeURIComponent(strDestination).split(',') : [];
        this.$store.dispatch('SET_SEARCH_ITEM', ['dest', destIdList.map((id) => this.destinationOptions.find((dest) => dest.value === id))]);
      } else {
        this.$store.dispatch('SET_SEARCH_ITEM', ['dest', []]);
      }
    },
    selecting() {
      this.$emit('optionSelect', true);
    },
    deselecting() {
      this.$emit('optionSelect', false);
    },
    closeDropDown() {
      this.countSelect = this.countSelect === 0 ? 1 : 0;
    },
    isHotDestination(pDestCode) {
      return this.hotDestinations.includes(pDestCode);
    },
  },
};
</script>
